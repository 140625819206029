import '@popperjs/core';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';
import Modal from 'bootstrap/js/src/modal';

const faballous = {
  init() {
    const url = window.location.search;
    const params = new URLSearchParams(url);

    // If URL contains form sent param, do popup.
    if (params.has('formulierVerzonden') || params.has('formSent') || params.has('formularzWysłany')) {
      // Set up modal
      const modalElement = document.getElementById('formSentModal');
      const formSentModal = new Modal(modalElement);
      formSentModal.show();

      // Reroute
      modalElement.addEventListener('hidden.bs.modal', () => {
        window.location.href = window.location.href.split('?')[0].toString();
      });
    }
  },
};

document.addEventListener('DOMContentLoaded', () => {
  faballous.init();
});
